import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
const app = createApp(App);

// Listener Start
import Cookies from 'js-cookie';
import AppStorage from "@/helpers/authservice/AppStorage";
import showNotification from "@/helpers/Toast/custom-toast";

app.config.productionTip = false;

// Vue.js içinde sayfa değişikliklerini dinleyen bir listener
router.beforeEach((to, from, next) => {
    const jwtToken = Cookies.get('jwt_token');

    // Sayfa değiştiğinde cookie'deki jwt_token kontrol ediliyor
    if (!jwtToken) {
        AppStorage.clear();
        // Token yoksa veya süresi dolmuşsa, logout işlemi yapabilirsiniz
        if (to.path !== '/admin/auth/login') {
            store.commit('logout'); // Bu, Vuex'ta bir "logout" mutasyonunu çağırabilir
            next('/admin/auth/login'); // Veya istediğiniz bir başka sayfaya yönlendirebilirsiniz
            showNotification('Your session has expired. please log in again!','warning',3000)
        } else {
            next();
        }
    } else {
        next();
    }
});
// Listener Finish


// bootstrap
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

// Import User Class
import User from './helpers/authservice/User';
window.User = User

// Import Money Plugin
// import money from 'v-money'
import money from 'v-money3'
app.use(money, {precision: 4})

import eventBus from './event-bus';

import Vue3Toasity, { ToastContainerOptions } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

app.use(
    Vue3Toasity,
    {
        autoClose: 3000,
        // diğer seçenekler...
    }, ToastContainerOptions
);
// import VueLazyLoad from 'vue3-lazyload'
// app.use(VueLazyLoad, {
//     loading: 'Loading...',
//     error: 'Image could not be loaded!',
//     lifecycle: {
//         loading: (el) => {
//             console.log('loading', el)
//         },
//         error: (el) => {
//             console.log('error', el)
//         },
//         loaded: (el) => {
//             console.log('loaded', el)
//         }
//     }
// })

import LazyComponent from "v-lazy-component";
app.use(LazyComponent);

// import VueNoty from 'vuejs-noty'
// app.use(VueNoty)

// modals
import '@/assets/sass/components/custom-modal.scss';

// perfect scrollbar
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

//vue-meta
import { createHead } from '@vueuse/head';
const head = createHead();

//Sweetalert
import Swal from 'sweetalert2';
window.Swal = Swal;

// nouislider - later remove and add to page due to not working in page
import VueNouislider from 'vue3-nouislider';
import 'vue3-nouislider/dist/vue3-nouislider.css';

// vue input mask
import Maska from 'maska';
app.use(Maska)
// smooth scroll
import { registerScrollSpy } from 'vue3-scroll-spy/dist/index';
registerScrollSpy(app, { offset: 118 });


//vue-i18n
import i18n from './i18n';

// datatables
import { ClientTable } from 'v-tables-3';

// json to excel
import vue3JsonExcel from 'vue3-json-excel';

//vue-wizard
import VueFormWizard from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';

// set default settings
import appSetting from './app-setting';
// import AppStorage from "@/helpers/authservice/AppStorage";
window.$appSetting = appSetting;
window.$appSetting.init();

app.use(store).use(router).use(i18n).use(PerfectScrollbar).use(VueNouislider).use(Maska).use(ClientTable).use(vue3JsonExcel).use(VueFormWizard).use(head).mount('#app');
