import Token from './Token';
import AppStorage from "./AppStorage";

class User{
    responseAfterLogin(res){
        const access_token = res.data.token
        if(Token.isValid(access_token)){
            AppStorage.store(access_token)
        }
    }
    hasToken(){
        const storeToken = localStorage.getItem('token');
        if(storeToken){
            return Token.isValid(storeToken)? true : false
        }
        return false
    }
    loggedIn(){
        return this.hasToken()
    }
    
    id(){
        if(this.loggedIn()) {
            const payload = Token.payload(localStorage.getItem('token'));
            return payload.sub
        }
        return false
    }
}
// eslint-disable-next-line no-class-assign
export default User = new User();
