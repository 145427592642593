<template>
    <!-- BEGIN FOOTER -->
    <div class="footer-wrapper">
        <div class="footer-section f-section-1">
            <p class="">{{ form.copyright }}</p>
        </div>
<!--        <div class="footer-section f-section-2">-->
<!--            <p class="">-->
<!--                Coded with-->
<!--                <svg-->
<!--                    xmlns="http://www.w3.org/2000/svg"-->
<!--                    width="24"-->
<!--                    height="24"-->
<!--                    viewBox="0 0 24 24"-->
<!--                    fill="none"-->
<!--                    stroke="currentColor"-->
<!--                    stroke-width="2"-->
<!--                    stroke-linecap="round"-->
<!--                    stroke-linejoin="round"-->
<!--                    class="feather feather-heart"-->
<!--                >-->
<!--                    <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>-->
<!--                </svg>-->
<!--            </p>-->
<!--        </div>-->
    </div>
    <!-- END FOOTER -->
</template>
<script>
import axios from "axios";
import VueEasymde from 'vue-easymde';
import 'easymde/dist/easymde.min.css';
import login from "@/views/auth/login";
import {API_BASE_URL} from "@/config";
import showNotification from "@/helpers/Toast/custom-toast";
export default {
    created(){
        // eslint-disable-next-line no-undef
        if(!User.loggedIn()){
            this.$router.push({name: 'login'})
            // showNotification('User Not Found!','warning',3000)
        }
        this.getSettings();
    },
    data(){
        return {
            form:{
                copyright: '',
            },
            errors:{},
        }
    },
    methods:{
        getSettings(){
            axios.get(API_BASE_URL+'admin/show/settings/')
                .then(({data}) => (this.form = data))
                .catch()
        }
    }
}
</script>
