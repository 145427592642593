<template>
    <div class="form auth-boxed">
        <div class="form-container outer">
            <div class="form-form">
                <div class="form-form-wrap">
                    <div class="form-container">
                        <div class="form-content">
                            <h1 class="">Sign In</h1>
                            <p class="">Log in to your account to continue.</p>

                            <form class="text-start" @submit.prevent="login">
                                <div class="form">
                                    <div id="username-field" class="field-wrapper input">
                                        <label for="email">Username:</label>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-user"
                                        >
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                        <input type="text" v-model="form.username" class="form-control" placeholder="Username"/>
                                    </div>

                                    <div id="password-field" class="field-wrapper input mb-2">
                                        <div class="d-flex justify-content-between">
                                            <label for="password">Password:</label>
                                            <router-link to="/admin/auth/pass-recovery" class="forgot-pass-link">Forgot Password?</router-link>
                                        </div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-lock"
                                        >
                                            <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                        </svg>
                                        <input :type="pwd_type" v-model="form.password" class="form-control" placeholder="Password" />
                                        <svg
                                            @click="set_pwd_type"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            id="toggle-password"
                                            class="feather feather-eye"
                                        >
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                            <circle cx="12" cy="12" r="3"></circle>
                                        </svg>
                                    </div>
                                    <div class="d-sm-flex justify-content-between">
                                        <div class="field-wrapper">
                                            <button type="submit" class="btn btn-primary">Log In</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {API_BASE_URL} from "@/config";
import showNotification from "@/helpers/Toast/custom-toast";

export default {
    created(){
        if(User.loggedIn()){
            this.$router.push({name: 'Home'})
            // showNotification('Successful','success',3000)
        }
    },

    data(){
        return {
            form:{
                username: null,
                password: null
            },
            errors:{},
        }
    },
    methods:{
        login(){
            axios.post(API_BASE_URL+'admin/login',this.form)
                .then(res => {
                    // eslint-disable-next-line no-console
                    User.responseAfterLogin(res)
                    
                    this.$router.push({ name: 'Home' })
                    showNotification('Successful','success',3000)
                    // window.location.href = '/';
                })
                .catch(error => this.errors = error.response.data)
        }
    }
}
</script>

<script setup>
import { ref } from 'vue';
import '@/assets/sass/authentication/auth-boxed.scss';

import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'Login' });

const pwd_type = ref('password');

const set_pwd_type = () => {
    if (pwd_type.value === 'password') {
        pwd_type.value = 'text';
    } else {
        pwd_type.value = 'password';
    }
};
</script>
