import Cookies from 'js-cookie';
class AppStorage{
    storeToken(token){
        localStorage.setItem('token',token);
    }
    store(token){
        this.storeToken(token)
        // Example using js-cookie library
        Cookies.set('jwt_token', token, { expires: 1 }); // Set the cookie to expire in 7 days

    }
    clear(){
        localStorage.removeItem('token')
    }
    getToken(){
        localStorage.getItem('token');
    }
}
// eslint-disable-next-line no-class-assign
export default AppStorage = new AppStorage();
