import TRANSITIONS, { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

function showNotification(content,type,timeout = 3000) {
    toast(content, {
        position: toast.POSITION.TOP_RIGHT,
        type: type,
        theme: toast.THEME.COLORED,
        transition: toast.TRANSITIONS.SLIDE,
        isLoading: false,
        autoClose: timeout,
        hideProgressBar: false,
    });
}

export default showNotification;
